var nameRegex = /^[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð\d &,.'-]*$/;
var getLengthForField = function (field) {
    switch (field) {
        case 'phoneNumber':
            return {
                minLength: 10,
                maxLength: 15,
            };
        case 'email':
            return {
                minLength: 6,
                maxLength: 50,
            };
        case 'firstName':
            return {
                minLength: 0,
                maxLength: 20,
            };
        case 'lastName':
            return {
                minLength: 2,
                maxLength: 20,
            };
        case 'company':
            return {
                minLength: 2,
                maxLength: 30,
            };
        case 'name':
            return {
                minLength: 2,
                maxLength: 50,
            };
        case 'participants':
        case 'maxPersonsPerReservation':
        case 'capacity':
        case 'pacing':
            return {
                minLength: 1,
                maxLength: 1000,
            };
        case 'capacityPercentage':
            return {
                minLength: 0,
                maxLength: 100,
            };
        case 'expectedOccupancyTime':
            return {
                minLength: 900,
                maxLength: 21600,
            };
        case 'slotInterval':
            return {
                minLength: 300,
                maxLength: 10800,
            };
        case 'minReservationNotice':
            return {
                minLength: 0,
                maxLength: 599940,
            };
        case 'comment':
            return {
                minLength: 0,
                maxLength: 1000,
            };
        case 'city':
        case 'street':
            return {
                minLength: 3,
                maxLength: 50,
            };
        case 'postalCode':
            return {
                minLength: 4,
                maxLength: 4,
            };
        case 'streetNumber':
            return {
                minLength: 1,
                maxLength: 10,
            };
        case 'password':
            return {
                minLength: 8,
                maxLength: 30,
            };
        case 'areaName':
            return {
                minLength: 2,
                maxLength: 20,
            };
        case 'areaCode':
            return {
                minLength: 1,
                maxLength: 8,
            };
        default:
            return undefined;
    }
};
var getDefaultAutoCompleteForField = function (field) {
    switch (field) {
        case 'firstName':
            return 'given-name';
        case 'lastName':
            return 'family-name';
        case 'phoneNumber':
            return 'tel';
        case 'email':
            return 'email';
        case 'comment':
            return 'text';
        case 'birthdate':
            return 'bday';
        case 'city':
            return 'address-level2';
        case 'postalCode':
            return 'postal-code';
        case 'street':
            return 'address-line1';
        case 'streetNumber':
            return 'address-line2';
        default:
            return undefined;
    }
};
var getAutoCompleteForField = function (field, autocomplete) {
    if (!autocomplete) {
        return undefined;
    }
    if (typeof autocomplete === 'string') {
        return autocomplete;
    }
    return getDefaultAutoCompleteForField(field);
};
var getFormatForField = function (field) {
    switch (field) {
        case 'firstName':
        case 'lastName':
            return nameRegex;
        case 'restaurantName':
            return /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&'\- ]*$/;
        case 'email':
            return EMAIL_REGEX;
        case 'password':
            // min 8 characters, at least 1 Uppercase, 1 number, 1 special character
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;
        case 'phoneNumber':
            return /^\+?[0-9 ]+$/;
        case 'postalCode':
            return /^[0-9]+$/;
        default:
            return undefined;
    }
};
var getInvalidFormatFieldErrorMessage = function (field) {
    switch (field) {
        case 'firstName':
        case 'lastName':
        case 'restaurantName':
            return 'core:errors.invalidName';
        case 'email':
            return 'core:errors.invalidEmail';
        case 'password':
            return 'core:errors.invalidPassword';
        case 'phoneNumber':
            return 'core:errors.invalidPhoneNumber';
        case 'postalCode':
            return 'core:errors.invalidFormat';
        default:
            return undefined;
    }
};
var EMAIL_REGEX = /^[A-Za-zÀ-ÖØ-öø-ÿÄÖÜäöüß0-9._%+-]+@[A-Za-zÀ-ÖØ-öø-ÿÄÖÜäöüß0-9.-]+\.[A-Za-zÀ-ÖØ-öø-ÿÄÖÜäöüß]{2,}$/;
export var InputFieldUtils = {
    getLengthForField: getLengthForField,
    getAutoCompleteForField: getAutoCompleteForField,
    getDefaultAutoCompleteForField: getDefaultAutoCompleteForField,
    getFormatForField: getFormatForField,
    getInvalidFormatFieldErrorMessage: getInvalidFormatFieldErrorMessage,
};
export { EMAIL_REGEX };
